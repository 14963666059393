const INFO = {
	main: {
		title: "Portafolio Catalina Schaufler",
		name: "Catalina Schaufler",
		email: "cata.schaufler@gmail.com",
		logo: "../logo.jpg",
	},

	socials: {
		twitter: "https://twitter.com/",
		github: "https://github.com/cataherrera",
		linkedin: "https://www.linkedin.com/in/catalina-sch%C3%A4ufler-herrera-321598158/",
	},

	homepage: {
		title: "Catalina Schaufler",
		description:
			"I'm Catalina Schaufler currently working at AWS hoping to learn and change lives a proyect at a time",
	},

	about: {
		title: "I’m Tharindu N. I live in Sri Lanka, where I design the future.",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to. If you're interested in any of the projects I've worked on, please feel free to check out the code and suggest any improvements or enhancements you might have in mind. Collaborating with others is a great way to learn and grow, and I'm always open to new ideas and feedback.",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Robo4Kids",
			description:
				"AWS Robo4Kids is a service that allows to extract all the complexity of logic programming for robots in a simple and easy graphical interface which can be used as a teaching tool for educational institutions, facilitating the learning of students in subjects such as robotics and programming and helping teachers and parents to educate future generations in useful skills for their professional development. ",
			linkText: "View Project",
			link: "https://github.com/cataherrera/robo4kids",
			link2: "https://github.com/cataherrera/Cloud4kids-Robo4kids",
			picture: "jetbot-blockly.png",

		},

		{
			title: "ESO Observatory Research",
			description:
				"Telescope Performance Monitoring Tool Kit January,Development for monitoring analyzing and displaying relevant performance metrics for the Very Large Telescope (VLT),Developed tool kit for monitoring, analyzing and displaying relevant performance metrics for the Very Large Telescope (VLT),Created models for parameters for re-calibration of the Very Large Telescope (VLT) positioning models, Created models for each focus of the Unit Telescope for the calibration and focus change processes, Created models and analyzed quality parameters of images in pre-observation processes ",
			linkText: "View Project",
			link: "https://github.com",
			// picture: "foto_catalina.jpg",
		},

		{
			title: "Project 3",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
			linkText: "View Project",
			link: "https://github.com",
			// picture: "foto_catalina.jpg",

		},

		{
			title: "Project 4",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com",
			picture: "",

		},

		{
			title: "Project 5",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com",
			picture: "",

		},
	],
};

export default INFO;
